.reset-style-btn.btn,
.reset-style-btn.btn:hover,
.reset-style-btn.btn:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiSelect-root.MuiSelect-select:focus {
  background-color: transparent !important;
}

body.modal-open {
  padding-right: 0 !important;
}

.MuiDialog-root .MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
}

* {
  outline-color: darkturquoise;
}