svg:not(:root) {
  overflow: unset !important ;
}

.dropdown.parked .parking-outline,
.dropdown.parked .parking-icon {
  stroke: var(--bgMain) !important;
}

.dropdown.show .parking-svg {
  stroke-width: 0px;
  fill: var(--bgMain) !important;
}

.dropdown.show .profile-icon {
  fill: var(--bgMain) !important ;
}

.profile-icon {
  width: 100%;
  height: 100%;
}

.outline-profile,
.person-profile {
  fill: none;
  stroke: black;
  stroke-width: 3px;
}

.dropdown.show .ruble-outline,
.dropdown.show .ruble-icon,
.dropdown.show .outline-profile,
.dropdown.show .person-profile,
.dropdown.show .parking-outline,
.dropdown.show .parking-icon {
  stroke: var(--bgMain) !important;
}
