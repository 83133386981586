.accordion-item-title.accrodion-item-title--no-bg:hover {
  background-color: initial;
}

.accrodion-item.accordion-item--hover-shodow {
  box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.3);
  transition: box-shadow, background-color 0.2s;
}

.accrodion-item.accordion-item--hover-shodow:hover {
  box-shadow: 2px 12px 10px -10px rgba(0, 0, 0, 0.3);
}
