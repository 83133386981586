@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

//standart color #1587c8

:root {
  --basicColor: #3e4543;
  --bgMain: #1587c8;
  --bgSecondary: #1587c8;
  --link: white;
  --linkHover: blue;
  --secondary-color: #3cb2f5;
  // Proxima-Nova
  // Exo-2
  // Core-Sans
  --fontFamily: 'Proxima-Nova';
}

$theme-colors: (primary: #1587c8);

$input-btn-font-family: var(--fontFamily);
$btn-font-family: var(--fontFamily);

@import '~bootstrap';

.text-secondary {
  color: var(--basicColor) !important;
}

.btn {
  font-family: var(--fontFamily);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bgMain);
}

a {
  color: var(--bgMain);
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--bgMain) !important;
}