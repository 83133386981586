.popUp {
  position: fixed;
  max-width: 270px;
  min-width: 270px;
  bottom: 65px;
  left: 3vw;
  z-index: 1000;
  padding: 15px;
  background-color: white;
  transition: all 1s ease-in-out;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.popUp--closed {
  bottom: calc(-100%);
  left: calc(-100%);
}

.popUp__title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}

.popUp__title-text {
  font-size: 22px;
  margin-right: 10px;
}

.popUp__title-close {
  font-size: 14px;
}

.popUp__content-wrapper {
  overflow: hidden;
  font-size: 14px;
}