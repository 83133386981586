div.receipts {
    background-color: #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
    .nowrap {
        white-space: nowrap;
    }
    table {
        th {
            padding: 1rem 0.5rem;
            text-align: left;
        }
        th:first-of-type {
            padding-left: 1rem;
        }
        tbody {
            tr:nth-child(2n-1) {
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
                td:first-of-type {
                    box-sizing: content-box;
                    border-left: 10px solid var(--secondary-color);
                }
            }
            tr {
                margin: 1rem 0;
                td {
                    padding: 0.5rem;
                    span {
                        padding: 0 1rem;
                    }
                }
            }

        }
    }
}