.accrodion-item:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-item-title {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    outline: none;
}

.accordion-item-title label {
    margin: 0;
    cursor: pointer;
}

.accordion-item-title:hover {
    background-color: #ddd;
}

.accordion {
    margin-bottom: 10px;
}

.accordion__body {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.accordion .accordion__arrow {
    color: #767676;
    right: 5px;
}

.accordion-item-title {
    padding-right: 20px;
}

.park-tip-info__body>a:hover {
    text-decoration: none;
}

.empty-session {
    margin-bottom: 10px;
}

header .dropdown-menu {
    font-size: 14px !important;
    min-width: 210px !important;
    z-index: 1151;
}

.dropdown-menu>a:hover {
    text-decoration: none;
}

.dropdown-menu-item {
    margin-top: 10px;
}

.parking__btn-container {
    text-align: center;
}

.parking__btn {
    width: calc(100% - 20px);
    margin-bottom: 10px;
    padding: 4px 0px !important;
    border-radius: 50px !important;
}