#map {
  min-height: 310px;
  overflow: hidden;
}

.map_banner {
  position: absolute;
  top: 35px;
  right: 20px;
  width: calc(100% - 40px);
  max-width: 510px;
  z-index: 900;
}

.map_banner>img {
  width: 100%;
}

.cluster {
  border-radius: 27px;
  width: 100px;
  height: 100px;
  background-color: var(--bgMain);
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  justify-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.leaflet-popup {
  position: absolute;
  transform: translate3d(0px, 0px, 0px) !important;
  bottom: 0 !important;
  left: 0 !important;
}

.leaflet-google-mutant {
  width: 100% !important;
  height: 100% !important;
}

.leaflet-control-container>.leaflet-right>.leaflet-bar {
  margin-right: 18px;
  bottom: 40vh;
}

.leaflet-top.leaflet-right {
  z-index: 900;
}

#info {
  width: 25%;
  height: 25%;
  /* background: rgba(255, 255, 255, 0.7); */
  position: absolute;
  top: 90px;
  left: 30px;
  padding: 5px;
  border: 1px solid grey;
  /* display: none; */
  z-index: 99999;
}

#find-box {
  position: absolute !important;
  top: 35px;
  left: 3vw;
  width: 270px;
  z-index: 901;
  background: white;
  color: grey;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.search-input {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 40px;
  border: 1px solid #dadada;
  font-size: 16px;
  color: gray;
}

.fa-search {
  margin-left: -25px;
}

.search-tooltip {
  overflow: hidden;
  overflow-y: auto;
  max-height: 60vh;
  list-style: none;
  padding: 0;
  z-index: 999;
  margin-bottom: 0;
}

.search-tip {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  font-size: 14px;
}

.search-tip__text {
  max-width: 80%;
  word-wrap: break-word;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.search-tip__icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.search-cancel {
  position: absolute;
  display: block;
  top: 13px;
  right: 18px;
}

.search-cancel>span {
  display: inline-block;
}

.search-cancel:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  #map {
    min-height: 260px;
    height: 100vh;
  }

  #find-box {
    position: relative;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 902;
  }

  .map_banner {
    width: 50%;
    top: 60px;
  }

  .search-input {
    border-top: 1px solid #e6e6e6;
    width: 100%;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
  }

  .search-tooltip {
    max-width: 100%;
  }

  .leaflet-control-container>.leaflet-right>.leaflet-bar {
    bottom: 115px;
  }
}