.transition--color {
  transition-duration: 1s;
  transition-property: color;
}

.main-mark::after {
  position: absolute;
  content: "*";
  display: block;
  width: 6px;
  height: 6px;
  top: -6px;
  right: -10px;
  color: red;
}