.dropdown-menu.show {
  display: block;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translate(0, 0) !important;
  top: 101% !important;
  border-radius: 0;
  /*transform: translateX(calc(-100% + 60px), 52px) translateZ(0) !important;*/
}

.controls-group .dropdown-menu-right {
  left: auto !important;
  right: -2px !important;
}
.controls-group .dropdown-item.active,
.controls-group .dropdown-item:active {
  background-color: var(--bgSecondary);
}

.dropdown-menu.show::before {
  top: -11px;
  border-bottom: 10px solid rgba(0, 0, 0, 0.25);
}

.controls-group .dropdown-menu {
  padding: 10px;
}

.profile-controls__group .dropdown-divider {
  height: 2px;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show {
    display: block;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    /*transform: translate(calc(-100% + 95.3px), 43.3px) translateZ(0) !important;*/
  }
}
