body {
  /*overflow-x: hidden;*/
  font-family: var(--fontFamily);
  color: var(--basicCoor) !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.container-fluid {
  margin: 0 auto;
  position: relative;
}

.inline-flex {
  display: flex;
}

.flex-center {
  align-items: center;
}

.none {
  display: none;
}

/*todo: remove !important*/
.btn-outline-danger {
  color: #ff6d3d !important;
  border-color: #ff6d3d !important;
}

.btn-outline-danger:hover {
  background: #f05e2e !important;
  color: #fff !important;
}