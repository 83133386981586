footer {
  z-index: 1000;
  width: 100%;
  background: var(--bgMain);
  flex-shrink: 0;
}

footer.fixed {
  position: fixed;
  bottom: 0;
}

.footer-content {
  width: 100%;
  color: #fff;
  opacity: 0.98;
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-content.row {
  margin-left: 0;
  margin-right: 0;
}

.footer__icon {
  margin-right: 5px;
}

.footer > .container-fluid > .row {
  margin-right: 0;
}

.footer__about-us {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.footer__phone {
  margin-right: 35px;
}

.footer-column {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.footer-column a {
  text-decoration: none;
}

.footer__apps-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer__apps-container > div {
  margin-right: -15px;
}

.apps {
  color: white;
  font-size: 20px;
  /*margin-right: 10px;*/
  margin-left: 20px;
}

.apps .icon-store {
  fill: #fff;
}

.apps:hover {
  color: white;
}

.menu-item-footer {
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.menu-item-footer > a {
  margin-left: 0.5em;
  color: rgb(255, 255, 255);
  padding: 0.25rem 1rem;
}

.menu-item-footer span {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.menu-item-footer a:hover {
  color: rgba(202, 202, 202, 0.9);
  text-decoration: none;
}

.menu-item-footer a.active {
  border-bottom: 2px solid var(--secondary-color);
}

.change-city-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  font-size: 14px;
  /* border: 0.5px solid #d8d8d8; */
  padding: 0px 10px;
  border-radius: 10px;
}

.change-city-btn:hover {
  color: #d2d2d2;
}

@media screen and (max-width: 881px) {
  .menu-item-footer > a {
    padding: 0.25rem 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
