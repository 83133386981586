.notification {
  display: flex;
  align-items: center;
  padding: 5px 0;
  background-color: var(--bgMain);
  min-height: 30px;
}

.notification__url,
.notification__url:hover,
.notification__url:visited {
  color: white;
}

.notification__message {
  color: white;
  width: calc(100% - 12px);
  padding-right: 3px;
}

.notification__close {
  cursor: pointer;
  font-size: 10px;
  color: white;
}
