.info-field {
  margin: 15px 0;
}

.info-field .name {
  color: #777;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.info-field .value {
  color: #555;
}

.sum {
  display: inline-block;
  position: relative;
  font-size: 18px;
  transform: translateY(1px);
}

.sum:not(.neutral) {
  padding-left: 12px;
}

.sum:before {
  position: absolute;
  left: -1px;
}

.sum.positive {
  color: #3c763d;
}

.sum.positive:before {
  content: '+';
  top: -1px;
}

.sum.positive .rest,
.sum.positive:after {
  color: #8eb391;
}

.sum.negative {
  color: #763e3c;
}

.sum.negative:before {
  content: '–';
}

.sum.negative .rest,
.sum.negative:after {
  color: #b38f8e;
}

.sum.sale.positive {
  color: #3c763d;
}

.sum.sale.positive:before {
  content: '–';
}

.sum.neutral {
  color: #333;
}

.sum.neutral .rest,
.sum.neutral:after {
  color: #999;
}

.sum:after {
  content: '₽';
  margin-left: 5px;
  color: grey;
}

.profile-parking .submit {
  font-size: 14px;
  border-radius: 15px;
}

.profile-parking .sessions-list {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile-parking .sessions-list .list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.profile-parking .sessions-list .list-item:last-child {
  border-color: transparent;
}

.profile-parking .sessions-list .buttons {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 3px;
}

.profile-parking .sessions-list .buttons .btn:not(:last-child) {
  margin-bottom: 10px;
}

@media (min-width: 1px) and (max-width: 351px) {
  .profile-parking .sessions-list .buttons {
    justify-content: center;
    flex-direction: row;
  }

  .profile-parking .sessions-list .buttons .btn:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media (min-width: 501px) and (max-width: 1100px) {
  .profile-parking .sessions-list .buttons {
    justify-content: center;
    flex-direction: row;
  }

  .profile-parking .sessions-list .buttons .btn:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media (min-width: 1450px) {
  .profile-parking .sessions-list .buttons {
    justify-content: center;
    flex-direction: row;
  }

  .profile-parking .sessions-list .buttons .btn:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.profile-parking .sessions-list .btn {
  border-radius: 20px;
  font-size: 13px;
}

.profile-parking .calculate-result {
  margin-bottom: 10px;
  margin-top: -10px;
  font-size: 13px;
  vertical-align: middle;
}

.profile-parking .sum {
  font-size: 16px !important;
  transform: none !important;
}

.profile-parking .md-option .sum {
  font-size: 14px !important;
}

.profile-parking .select-value .work-time {
  display: none;
}

@media (max-width: 541px) {
  .profile-parking .table {
    min-width: 700px;
  }
}

.profile-operations,
.profile-parking {
  margin-bottom: 30px;
}

.profile-operations .title,
.profile-parking .title {
  display: flex;
  font-weight: normal;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  color: #555;
}

.profile-operations .filters,
.profile-parking .filters {
  border-right: 1px solid #eee;
  padding-right: 15px !important;
}

@media (max-width: 576px) {
  .profile-operations .filters {
    border-right: none;
  }
}

.profile-operations .filters select,
.profile-parking .filters select {
  width: 100%;
  font-size: 15px;
  height: 30px;
  border-color: grey;
  color: grey;
  background: #fff;
}

.profile-operations .operations,
.profile-parking .operations {
  overflow: auto;
  max-height: 600px;
}

@media (max-width: 541px) {
  .profile-operations .operations .table,
  .profile-parking .operations .table {
    min-width: 600px;
  }
}

.profile-operations .operations td,
.profile-parking .operations td {
  vertical-align: middle;
}

.profile-operations .operations th,
.profile-parking .operations th {
  border: 0;
}

.profile-operations .info-field .value .btn,
.profile-parking .info-field .value .btn,
.profile-parking .payment {
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 11px;
  border-radius: 17px;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.profile-operations .info-field .value .btn.btn-options-grey,
.profile-parking .info-field .value .btn.btn-options-grey {
  color: grey !important;
  background: #fff;
  border: 1px grey solid;
}

.profile-operations .error-buttons,
.profile-parking .error-buttons {
  margin-top: 15px;
}

.profile-operations .error-buttons .btn:first-child,
.profile-parking .error-buttons .btn:first-child {
  margin-right: 10px !important;
}

.profile-operations .date-pickers,
.profile-parking .date-pickers {
  display: flex;
  justify-content: flex-start;
}

.profile-operations .react-datepicker-popper {
  z-index: 1003;
}

.profile-operations .date-pickers .delimiter:before,
.profile-parking .date-pickers .delimiter:before {
  content: '—';
  margin-right: 10px;
  margin-left: 10px;
}

.profile-abonements {
  margin-bottom: 30px;
}

.profile-abonements .main-wrapper {
  color: #333;
  margin-top: 15px;
}

@media (max-width: 541px) {
  .profile-abonements .table {
    min-width: 700px;
  }
}

.profile-abonements .title {
  display: flex;
  font-weight: normal;
  font-size: 1.2rem;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  color: #555;
}

.profile-abonements th {
  border: 0;
}

.profile-abonements td {
  vertical-align: middle;
}

.profile-abonements .error-buttons {
  margin-top: 15px;
}

.profile-abonements .error-buttons .btn:first-child {
  margin-right: 10px !important;
}

.profile-info {
  margin-bottom: 15px;
  position: relative;
  background: #eee;
}

.profile-info .main-wrapper {
  margin-bottom: 20px;
}

.profile-info .spinner-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  color: #009bd9;
}

.profile-info .title,
.coop-ts .title {
  display: flex;
  font-weight: normal;
  font-size: 1.2rem;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
  color: #555;
}

.profile-info .action-button,
.coop-ts .action-button {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  color: #555;
  margin-left: 10px;
}

.profile-info .action-button:hover,
.profile-info .action-button:active,
.profile-info .action-button:focus {
  text-decoration: none;
}

.profile-info .action-button span {
  margin-left: 5px;
}

.profile-info .logout {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-size: 12px !important;
  padding: 10px 30px;
}

.profile-info .state-number-item,
.coop-ts .coop-ts__item {
  z-index: 1;
  padding: 10px;
  border: 0;
  margin: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}

.profile-info .state-number-item:last-child {
  border: 0;
}

.profile-info .state-number-info,
.profile-info .document-description {
  color: #777;
  font-size: 14px;
  font-weight: bold;
}

.profile-info .state-number-info {
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}

.profile-info .editable-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile-info .editable-item .name {
  margin-bottom: 5px;
}

.profile-info .editable-item .item-content {
  flex: 1;
}

.profile-info .editable-item .buttons,
.coop-ts .editable-item .buttons {
  display: flex;
  align-self: center;
}

@media screen and (max-width: 420px) {
  .profile-info .editable-item .buttons {
    align-self: flex-start;
  }
}

.translate {
  display: block;
  -webkit-transform: translate(-5px, -4px);
  -moz-transform: translate(-5px, -4px);
  -ms-transform: translate(-5px, -4px);
  -o-transform: translate(-5px, -4px);
  transform: translate(-5px, -4px);
}

.profile-info .editable-item .buttons .btn,
.coop-ts .editable-item .buttons .btn {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  color: #555;
}

.profile-info .error-buttons {
  margin-top: 15px;
}

.profile-info .error-buttons .btn:first-child {
  margin-right: 10px !important;
}

.state-number {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  border: 1px solid #888;
  border-radius: 4px;
  width: 145px;
  color: #333;
  text-transform: uppercase;
}

.state-number .main-number {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 10px;
  border-right: 1px solid #888;
  /*border-radius: 3px;*/
}

.state-number .main-number .first-letter {
  margin-right: 3px;
}

.state-number .main-number .last-letters {
  margin-left: 3px;
}

.state-number .region {
  padding: 2px 7.5px;
  border-left: 1px solid #888;
  /*border-radius: 3px;*/
  transform: translateX(-1px);
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}

.state-number .region .number {
  font-size: 12px;
}

.state-number .region .country {
  font-size: 8px;
}

.state-number:before,
.state-number:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 3px;
  height: 3px;
  display: inline-block;
  background: #888;
  border-radius: 50%;
  transform: translateY(-50%);
}

.state-number:before {
  left: 2px;
}

.state-number:after {
  right: 2px;
}

.edit-profile-modal .md-form-group {
  margin: 10px 0 1px;
}

.edit-profile-modal .change-button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.edit-profile-modal .breadcrumb {
  margin: 0;
  padding: 5px 5px 5px 0;
  background: transparent;
}

.change-modal {
  padding: 15px 30px;
}

#profile-main {
  padding: 0;
}

.profile-menu {
  margin-bottom: 15px;
  background: #fff;
}

.profile-menu .item {
  padding: 15px 15px 5px;
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #666;
  border-bottom: 2px solid transparent;
}

.profile-menu .item:hover {
  text-decoration: none;
}

.profile-menu .item:not(.active):hover {
  color: #000;
}

.profile-menu .item.active {
  color: #000;
  border-color: var(--secondary-color);
}

.profile-menu .fill {
  padding: 0 10px;
}

.payment-component .text-info {
  font-size: 13px;
  margin: 10px 0 0;
}

.payment-component .btn {
  border-radius: 20px;
  font-size: 13px;
  appearance: none;
}

.payment-component .error {
  margin-top: 8px;
  font-size: 14px;
  color: red;
}

.payment-component .preloader {
  margin-left: 10px;
}

.payment-component .btn.cancel {
  margin-left: 10px;
  border-radius: 20px;
  font-size: 14px;
}

.payment-component .result {
  font-size: 14px;
}

.modal-dialog.payment-component {
  max-width: 500px;
}
